import React from 'react';
import { useRef, useEffect } from "react";
import "./Finergic.css";
import "./Fitbit.css"
import DarkNav from './DarkNav';
import Footer from './Footer';
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import SmoothScroll from "smooth-scroll";
import ScrollToTop from "react-scroll-to-top";
import transition from "../transition";



function Finergic() {

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    var scroll = new SmoothScroll('a[href*="#"]', {
        speed: 300,
    });


  const { scrollYProgress } = useScroll();
  const { scrollY } = useScroll();
  const x = useTransform(scrollY, [3000,5000], [0, -700])


    const scaleX = useSpring(scrollYProgress, {
      stiffness: 100,
      damping: 30,
      restDelta: 0.001
    });

    const ref = useRef(null);
    const { scrollXProgress } = useScroll({ container: ref });


  return (
    <>

        <DarkNav />
    <motion.div className="progress-bar" style={{ scaleX }} />
        <div className='finergic'>
            
            <div className='finergic_Header'>
                <div className='homeLeft_finergic'>
                    <div className='homeLeft_Top_finergic'>
                        <p>Rebranding</p>
                    </div>
                    <div className='homeLeft_Bottom_finergic'>
                        <p>A fintech</p>
                        <p>Company,</p>
                        <p>Finergic</p>
                    </div>
                </div>
                <div className='project_finergic_Info'>
                    <div className='project_finergic_Info_Timeline'>
                        <h5>TIMELINE</h5>
                        <p>Research // 2 week</p>
                        <p>Brand Design // 4 week</p>
                        <p>Visual Design // 3 weeks </p>
                        <p>Development // 2 weeks</p>
                    </div>
                    <div className='project_finergic_Info_Role'>
                        <h5>ROLE</h5>
                        <p>UX Designer</p>
                    </div>
                    <div className='project_finergic_Info_Tools'>
                        <h5>TOOLS</h5>
                        <p>Figma</p>
                    </div>
                    <div className='project_finergic_Info_Teams'>
                        <h5>TEAMS</h5>
                        <p>Michelle Loke (Project Manager), Jeewoon (Logo Designer) , Melody Bay (Content Writer) and I</p>
                    </div>
                    <div className='project_finergic_Info_ProjectType'>
                        <h5>PROJECT TYPE</h5>
                        <p>Industry</p>
                    </div>
                    <div className='project_finergic_Info_ProjectFocus'>
                        <h5>PROJECT FOCUS</h5>
                        <p>Research</p>
                        <p>Brand Design</p>
                        <p>Visual Design</p>
                    </div>
                </div>
            </div>

            <div style={{margin:"25px 0px"}}>
                <p>PROJECT SHOWREEL</p>
            </div>

            <div className='project_Video'>
            <div className='overlay'>
                    
                    </div>
                <video autoPlay loop>
                            <source src="/images/finergicvideo.mp4" type="video/mp4"/>
                        </video>     
            </div>

            <div className='project_finergic_Overview'>
                <div className='project_finergic_Overview_Left'>
                    <p>OVERVIEW</p>
                    <div className='project_finergic_Overview_Left_Link'>
                       <p> <a data-scroll style={{textDecoration:"none", color:"#000000"}} href='#solution'> SKIP TO THE JUICY PART</a></p> 
                        <img src='/images/Link_Arrow_Fitbit.svg' alt="link arrow" />
                    </div>
                </div>
                <div className='project_finergic_Overview_Right'>
                    <p>Finergic is a fintech company located in Singapore. With its experienced team, they help banks to design and implement Business Solutions with cutting edge Technology. I have worked on rebranding, redesigning, and developing their website. The goal was to make it more appealing to the existing and future clients and users and also open opportunities for potential future candidates looking for jobs.</p>
                </div>
            </div>

            <div className='project_finergic_Vague_ProblemStatement'>
                <h2>THE PROBLEM STATEMENT</h2>
                <p>Before beginning the design journey I went on to define the problem statement that helped me design and stay on track in the later stages</p>
                <p>How might we create a new brand identity that reflects Finergic's values and mission and resonates with target audience creating a cohesive and consistent user experience?</p>
            </div>

            {/* <div className='project_finergic_Vague_WhyRedesign'>
                <p>But why is redesign necessary?</p>
                <img src="/images/Fitbit_Redesign.png" alt="old firbit screenshots" />
            </div> */}

            {/* <div className='project_finergic_Border'></div> */}

            <div className='project_finergic_Research_Header'>
                <div className='project_finergic_Research_Header_Left'>
                <div className='project_finergic_Research_Header_Left_Top'>
                  <p>1.</p>
                </div>
                <div className='project_finergic_Research_Header_Left_Bottom'>
                  <p>2.</p>
                </div>

                </div>
                <div className='project_finergic_Research_Header_Right'>
                    <div className='project_finergic_Research_Header_Right_Top'>
                        <h1>CLIENT REQUEST:</h1>
                        <p>  &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp;  We were asked to create a MVP of their website with a redesigned brand which would allow other business to trust their brand and also open door to potential new candidates to expand their business.</p>
                    </div>
                    <div className='project_finergic_Research_Header_Right_Middle'></div>
                    <div className='project_finergic_Research_Header_Right_Bottom'>
                        <h1>OUR APPROACH:</h1>
                        <p> &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; After thoroughly studying the concept, the client's industry, and the target audience, we discovered all the problems with the client's current brand and website. We brainstormed new ideas and worked closely with the client to create a new website that would definitely succeed in its field.</p>
                    </div>
                </div>
            </div>

                <motion.div className='project_finergic_Secondary_Research_Wrapper' style={{x}}>
                   <p>PROBLEM</p>
                   <img src='/images/Right_Arrow.svg' alt="arrow" />
                    <p>SOLUTION</p>
                </motion.div>

            <div className='project_finergic_Border'></div>

            <div className='project_finergic_ps'>
              <div className='project_finergic_ps_left'>
                <div className='project_finergic_ps_left_left'>
                  <h1>Problems:</h1>
                </div>
                <div className='project_finergic_ps_left_right'>

                  <div className='project_finergic_ps_left_right_p1'>
                    <img src="/images/bag.svg" alt="bag" />
                    <h1>Difficulty in building trust and finding talents</h1>
                    <p>Website did not have a proper careers page and the only way to apply for the company was to fill out a common form.</p>
                  </div>

                  <div className='border2'></div>

                  <div className='project_finergic_ps_left_right_p1'>
                    <img src="/images/trend.svg" alt="trend" />
                    <h1>Low conversion rate</h1>
                    <p>The website was aesthetically a visual dumping ground that did not do justice to the brand's purpose making brand untrustworthy.</p>
                  </div>

                  <div className='border2'></div>

                  <div className='project_finergic_ps_left_right_p1'>
                    <img src="/images/compass.svg" alt="compass" />
                    <h1>Poor navigation</h1>
                    <p>Some Navbar links navigated to the same page items while others navigated to different pages, resulting in inconsistency.</p>
                  </div>

                </div>
              </div>
              <div className='project_finergic_ps_right'>
                <div className='project_finergic_ps_right_left'>
                  <h1>Solutions:</h1>
                </div>
                <div className='project_finergic_ps_right_right'>
                <div className='project_finergic_ps_left_right_p1'>
                    <img src="/images/book.svg" alt="book" />
                    <h1>Kickoff to understand vision and prespective</h1>
                    <p>We conducted an extensive kickoff meetings with the stakeholders to understand what are the values that they are looking for in their new brand.</p>
                  </div>

                  <div className='border2'></div>

                  <div className='project_finergic_ps_left_right_p1'>
                    <img src="/images/medal.svg" alt="medal" />
                    <h1>Brand Redesign</h1>
                    <p>We used a lot of visual "tricks" to make the site stand out from the competitors and inspire trust in the process - for us to drive our client's conversion rates higher.</p>
                  </div>

                  <div className='border2'></div>


                  <div className='project_finergic_ps_left_right_p1'>
                    <img src="/images/flow.svg" alt="flow" />
                    <h1>Site Map</h1>
                    <p>We organized the information in a structured, coherent way to help visitors understand better how the website works.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='project_finergic_Border'></div>

            <div className='project_Research_Header'>
                <div className='project_Research_Header_Left'>
                <div className='project_Research_Header_Left_Top'>

                </div>
                <div className='project_Research_Header_Left_Bottom'>
                    <p>Stages:</p>
                </div>

                </div>
                <div className='project_Research_Header_Right'>
                    <div className='project_Research_Header_Right_Top'>
                        <h1>Research</h1>
                        <h1>Process</h1>
                    </div>
                    <div className='project_Research_Header_Right_Bottom'>
                        <p>1. Kickoff Meetings</p>
                        <p>2. Kickoff Meetings</p>
                        <p>3. Site Map</p>
                    </div>
                </div>
            </div>


            <div className='project_finergic_Secondary_Research project_IA'>
                <div className='project_finergic_Secondary_Research_Wrapper project_IA_Wrapper'>
                    <div className='project_finergic_Secondary_Research_Wrapper_Left'>
                        <p>KICKOFF MEETINGS</p>
                    </div>
                    <div className='project_finergic_Secondary_Research_Wrapper_Right'>
                        <p>We started our project by conducting kickoff meetings with the client. It took us few kickoff meetings to understand brand vision, overall goals and challenges. These meetings were super helpful to envision and make brand suggestions.</p>
                    </div>
                </div>
                <div className='project_carousel'>
      <svg id="progress" width="100" height="100" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="30" pathLength="1" className="bg" />
        <motion.circle
          cx="50"
          cy="50"
          r="30"
          pathLength="1"
          className="indicator"
          style={{ pathLength: scrollXProgress }}
        />
      </svg>
      <ul ref={ref}>
        <img src="/images/K1.svg" alt="kickoff deck" />
        <img src="/images/K2.svg" alt="kickoff deck" />
        <img src="/images/K3.svg" alt="kickoff deck" />
        <img src="/images/K4.svg" alt="kickoff deck" />
        <img src="/images/K5.svg" alt="kickoff deck" />
        <img src="/images/K6.svg" alt="kickoff deck" />
        <img src="/images/K7.svg" alt="kickoff deck" />
        <img src="/images/K8.svg" alt="kickoff deck" />
        <img src="/images/K9.svg" alt="kickoff deck" />
      </ul>
    </div>
            </div>

            <div className='project_finergic_Border'></div>


            <div className='project_finergic_Primary_Research'>
                <div className='project_finergic_Primary_Research_Top'>
                    <div className='project_finergic_Primary_Research_Top_Left'>
                        <h2>BRAND REDESIGN</h2>
                        <p>After having multiple brainstorming session and design workshops with the client we understood their brand’s mission and vision. We created few iterations of brand presentation kit concepts with different logos, color palette, shapes etc.</p>
                    </div>
                    <div className='project_finergic_Primary_Research_Top_Right'>
                        <img src="/images/Brandkit1.svg" alt="brand kit 1" />
                    </div>
                </div>
                <div className='project_finergic_Primary_Research_Bottom'>
                    <div className='project_finergic_Primary_Research_Bottom_Left'>
                        <img src="/images/Brandkit2.svg" alt="brand kit 2" />
                    </div>
                    <div className='project_finergic_Primary_Research_Bottom_Right'>
                        <img src="/images/Brandkit3.svg" alt="brand kit 3" />
                    </div>
                </div>
            </div>

            <div className='project_finergic_BCMIntro'>
                <p>After providing them with all the pros and cons of each brand it, the client decided to move forward with kit number 2 as it aligned more with their brand. </p>
            </div>

            <div className='project_finergic_Brandkit'>
                <img src="/images/BrandKit.png" alt="brand kit" />
            </div>

            <div className='project_finergic_Border'></div>


            <div className='project_Secondary_Research project_IA'>
                <div className='project_Secondary_Research_Wrapper project_IA_Wrapper'>
                    <div className='project_Secondary_Research_Wrapper_Left'>
                        <p>SITE MAP</p>
                    </div>
                    <div className='project_Secondary_Research_Wrapper_Right'>
                        <p>Our goal was to reimagine the entire website and break it down into logical blocks with the correct positioning of the product with a smart structure of the information, laid out to the visitor. <br /> <br /> That's why one of our first suggestions was to create a new sitemap to do everything from scratch. To do this, we needed to completely rethink our way around the existing site and share the right information the right way, for each page to focus on its own idea.</p>
                    </div>
                </div>
                <img src="/images/FinergicIA.svg" alt='Information architecture' />
            </div>


            <div className='project_Research_Header old_version'>
                <div className='project_Research_Header_Left old_version_left'>
                <div className='project_Research_Header_Left_Top'>

                </div>
                <div className='project_Research_Header_Left_Bottom'>
                    <p>Compare:</p>
                </div>

                </div>
                <div className='project_Research_Header_Right old_version_right'>
                    <div className='project_Research_Header_Right_Top'>
                        <h1>Old</h1>
                        <h1>Version</h1>
                    </div>
                    <div className='project_Research_Header_Right_Bottom'>
                        <p>  &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp;  We analyzed the client's existing website and improved it by making it more engaging visually, easier to navigate and even more intuitive in terms of the information presented. We didn't remove any existing features. Instead, we improved on what we had and added new ones, only where they were needed.</p>
                    </div>
                </div>
            </div>

            <ReactCompareSlider className='slider'
                itemOne={<ReactCompareSliderImage src="/images/compare1.svg" alt="Image one" />}
                itemTwo={<ReactCompareSliderImage src="/images/compare2.svg" alt="Image two" />}
                style={{
                    height: '100vh',
                    width: '100%'
                  }}
                  changePositionOnHover={true}
            />


<div className='project_Research_Header old_version' id='solution'>
                <div className='project_Research_Header_Left old_version_left'>
                <div className='project_Research_Header_Left_Top'>

                </div>
                <div className='project_Research_Header_Left_Bottom'>
                    <p>Final Redesign:</p>
                </div>

                </div>
                <div className='project_Research_Header_Right old_version_right'>
                    <div className='project_Research_Header_Right_Top'>
                        <h1>Design</h1>
                        <h1>Process</h1>
                    </div>
                    <div className='project_Research_Header_Right_Bottom'>
                        <h1> &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; We completely redesigned the website and divided it into several pages, making it easier for the visit or to navigate and find the information they need the most.</h1>
                        <p> After analyzing the website and understanding the objective of the team, we structured all the information that was previously scattered all over the place into a beautiful, captivating hierarchy which makes it is easy to find the information the visitor needs.</p>
                    </div>
                </div>
            </div>

            <div className='project_finergic_Border'></div>

            <div className='project_finergic_Secondary_Research homepage'>
                <div className='project_finergic_Secondary_Research_Wrapper project_IA_Wrapper'>
                    <div className='project_finergic_Secondary_Research_Wrapper_Left'>
                        <p>HOME PAGE</p>
                    </div>
                    <div className='project_finergic_Secondary_Research_Wrapper_Right'>
                        <p>Our first task was to create a bright, engaging home page and show the main features of the product: Wealth Solutions, Technology and Architecture & Core banking Solutions along with testimonials o build trust </p>
                    </div>
                </div>
                <div className='video' >
                    <video autoPlay loop>
                        <source src="/images/Finergic1.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>

            <div className='project_finergic_Border'></div>

            <div className='project_finergic_Secondary_Research homepage'>
                <div className='project_finergic_Secondary_Research_Wrapper project_IA_Wrapper'>
                    <div className='makeflex'>
                    <div className='project_finergic_Secondary_Research_Wrapper_Left changeflex1'>
                        <p>SERVICES PAGE</p>
                    </div>
                    <div className='project_finergic_Secondary_Research_Wrapper_Right changeflex2'>
                        <p>The home page and the services page have listings of several services that finergic offers to the clients. Once the client views the service as per their liking they they contact the team and start their journey.</p>
                    </div>
                    </div>
                    <div className='project_finergic_Secondary_Research_Wrapper_Right_Right'>
                        <img src="/images/Finergic2.svg" alt="services" />
                    </div>
                </div>
            </div>

            <div className='project_finergic_Border'></div>

            <div className='project_finergic_Secondary_Research homepage'>
                <div className='project_finergic_Secondary_Research_Wrapper project_IA_Wrapper'>
                    <div className='project_finergic_Secondary_Research_Wrapper_Left'>
                        <p>CAREERS PAGE</p>
                    </div>
                    <div className='project_finergic_Secondary_Research_Wrapper_Right'>
                        <p>Job baord with different job cards are posted according to the opening in different regions of the world. Also it’s just a form click away for the job poster to post the job, yep that easy.</p>
                    </div>
                </div>
                <div className='video2' >
                    <video autoPlay loop>
                        <source src="/images/Finergic3.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>














































           

            <div className='project_finergic_Reflection'>
                <h2>REFLECTION</h2>
                <div className='project_finergic_Reflection_Text'>
                    <div className='project_finergic_Reflection_Text_Left'>
                        <img src="/images/Reflection1.svg" alt="reflection image" />
                        <h2>Take Aways</h2>
                        <h3>Redesigning a wesbite is a completely new experience (even though I’ve done it before) then creating a completely new website. So here are my reflections for this project.</h3>
                        <ol>
                            <li>Project doesn't always gets completed in time, they can still ask for iterations in the later stages of development too. Keeping calm and thinking of a 2-3 solutions/alternatives is the most viable option at that time.</li>

                            <li>Finding images/video that aligns with the brand is always a task. </li>

                            <li>Working with a logo designer, content writer and the client was an amazing experience. I got to learn about their thinking process and how do they approach something. Woking so closely with the client (multiple team members of Finergic) helped me gain insights about what they were envisioning for their brand.</li>

                            <li>Even though there were A LOT of iteration and changes I got to know what I was doing wrong and how I could improve it. </li>
                        </ol>
                    </div>
                </div>
            </div>
            <ScrollToTop 
                color='#FB8842'
                top={500} smooth />
       
        </div>
        <Footer />
        </>
  )
}

export default transition(Finergic);