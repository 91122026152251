import React, { useRef, useEffect, useState } from 'react';
import "./Home.css";
import Projects from './Projects';
import gsap from "gsap";
import SplitType from 'split-type';
import Navbar2 from './Navbar2';
import {motion} from "framer-motion";
import Testimonials from './Testimonials';
import Footer from './Footer';
import DarkNav from "./DarkNav"


function Home() {

  const [isMobile, setIsMobile] = useState(false);

  const zoomRef = useRef(null);
    const scrollRef = useRef(null);



    useEffect(() => {
        const myText = new SplitType("#homeLeft_Bottom")
        gsap.to(".char", {
            y:0,
            stagger: 0.05,
            duration: 0.3
        })
    }, [])


  return (
    <>
          <Navbar2 />
    <div className='home' ref={scrollRef} >
      {/* {isMobile?<Navbar />:<Navbar2 /> }
      <Navbar /> */}
      <div className='home_Wrapper' ref={zoomRef}>

      <div className='homeLeft'>
        <div className='homeLeft_Top'>
          <p>Purposeful</p>
        </div>
        <div className='homeLeft_Bottom'>
          <p>Designs with</p>
          <p>Empathy &</p>
          <p>Aesthetics</p>
        </div>
      </div>

      <div className='homeRight'>
        <p>I carefully tailor my design interfaces, systems, and products to match the subtle complexities of human behavior, ensuring a seamless and intuitive user experience.</p>
        <p>Associate Experience Designer at <a target="_blank" href='https://www.rocketmortgage.com'> <br />🚀 Rocket Mortgage</a>.</p>
      </div>

    </div>
    <Projects />

    <Footer />

    </div>
  </>
  )
}

export default Home
