import React, { useEffect } from 'react';
import "./Projects.css";
import Footer from './Footer';
import { Link } from 'react-router-dom';
// import Swup from 'swup';
// import SwupOverlayTheme from '@swup/overlay-theme';



function Projects() {

  // useEffect(() => {

  //   const swup = new Swup({
  //     plugins: [new SwupOverlayTheme()]
  //   });  },[])


  
  return (
    <div className='projects'>
      <div className='projects_Wrapper'>
      <div className='projects_title'>
      <div className='Project_Category'>
          <p>My Past</p>
          <p><span>Projects </span></p>
        </div>
        <img src="/images/Line.svg" alt="line" />
      </div>
        <div className='projects_border'></div>

        <Link className='rocket_Link transition-main' to="/case-studies/rocket-mortgage">
        <div className='project'>
        <div className='projectDetails'>
            <div className='projectDetailsName'>
            <div style={{ display:"flex"}}>
                <h2>Designing State of the Art Lead Generation form for Rocket Mortgage</h2>
                {/* <img className='show' src='./images/Link_Arrow.svg' alt="arrow" /> */}
                <img className='hide' src='./images/Link_Arrow_Hovered.svg' alt="arrow" />              </div>
              <p>Architecting personalized, smart question paths to drive user engagement and generate qualified mortgage leads for Rocket Mortgage and generating <b>$2M in profits</b>.</p>
              
              {/* <p style={{fontSize: "17px"}}>Rocket - Case Study</p>
              <br />
              <br /> */}
              <div className='status'>
                <p>Live <small>🟢</small></p>
              </div>
            </div>
            
            <div className='projectDetailsName_Type'>
              <p>EXPERIENCE DESIGN & STRATEGY</p>
              <p>2023</p>
            </div>
            
          </div>

          <div className='projectImage'>
            <img src="./images/Rocket2.svg" alt="Rocket Project" />
          </div>


        </div>
        </Link>




        <div className='projects_border2'></div>

        <Link className='finergic_Link' to="/case-studies/finergic">
        <div className='project'>
        <div className='projectDetails'>
            <div className='projectDetailsName'>
            <div style={{ display:"flex"}}>
                <h2>Rebranding a fintech company</h2>
                {/* <img className='show' src='./images/Link_Arrow.svg' alt="arrow" /> */}
                <img className='hide' src='./images/Link_Arrow_Hovered.svg' alt="arrow" />              </div>
              <p>Crafting a trustworthy fintech visual brand identity and website MVP to spur business growth for the expanding startup Finergic.</p>
              {/* <p style={{fontSize: "17px"}}>Rocket - Case Study</p>
              <br />
              <br /> */}
              <div className='status'>
                <p>Live <small>🟢</small></p>
              </div>
            </div>
          
            
            <div className='projectDetailsName_Type'>
              <p>VISUAL DESIGN</p>
              <p>2021</p>
            </div>
            
          </div>

          <div className='projectImage'>
            <img src="./images/Finergic2.1.svg" alt="Finergic Project" />
          </div>

        </div>
        </Link>


        <div className='projects_border2'></div>


        <Link className='fitbit_Link' to="/case-studies/fitbit">
        <div className='project'>
          <div className='projectDetails'>
            <div className='projectDetailsName'>
            <div style={{ display:"flex"}}>
                <h2>Designing for behavior change in Fitbit</h2>
                {/* <img className='show' src='./images/Link_Arrow.svg' alt="arrow" /> */}
                <img className='hide' src='./images/Link_Arrow_Hovered.svg' alt="arrow" />
              </div>
              <p>Strategizing behavior-changing design solutions for Fitbit to cultivate an empowering water hydration habit amongst their health-focused community.</p>
              {/* <br />
              <br /> */}
              <div className='status status2'>
                <p>Academic <small>📕</small></p>
              </div>
            </div>
            <div className='projectDetailsName_Type'>
              <p>EXPERIENCE DESIGN</p>
              <p>2023</p>
            </div>
            </div>

          <div className='projectImage'>
            <img src="./images/Fitbit.png" alt="Fitbit Project" />
          </div>

        </div>
        </Link>


        {/* <div className='projects_border2'></div> */}


        {/* <Link className='iu_Link' to="/case-studies/iu-jobs">
        <div className='project'>
        <div className='projectDetails'>
            <div  className='projectDetailsName'>
              <div style={{ display:"flex"}}>
                <h2>Helping International students find right on-campus jobs at IU</h2>
                <img className='show' src='./images/Link_Arrow.svg' alt="arrow" />
                <img className='hide' src='./images/Link_Arrow_Hovered.svg' alt="arrow" />              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <br />
              <br />

            </div>
            <div className='projectDetailsName_Type'>
              <p>EXPERIENCE DESIGN</p>
              <p>2022</p>
            </div>
            
          </div>

          <div className='projectImage'>
            <img src="./images/IUJobs2.svg" alt="IUJobs Project" />
          </div>
        </div>
        </Link> */}
        </div>
<div className='recc'>
        <div className='projects_title'>
      <div className='Project_Category Project_Category2'>
          <p>Heartfelt</p>
          <p><span>Recommendations </span></p>
        </div>
        <img src="/images/Line.svg" alt="line" />
      </div>

      <div className='recommendation1'>
        <div className='recommendation1_Left'>
          <p>Working with Tushar Sharma has been a fantastic experience. He has consistently proven himself as an exceptional design partner. Tushar's responsiveness to feedback and projects is unmatched, always approaching them with empathy for the customer and a sense of urgency for the business. His understanding of our business goals and customer needs has been impressive, leading to fresh and creative solutions for each project. I thoroughly enjoyed collaborating with him, his talent and dedication would undoubtedly be a valuable asset to any organization.</p>
          <div className="title">
            <h2>Karran Gupta</h2>
            <p>Digital Product Manager | <span>Rocket Mortgage</span></p>
          </div>
        </div>
        <div className='recommendation1_Right'>
          <img src="./images/Karran.png" alt="photo" />
        </div>
      </div>

      <div className='recommendationDuo'>

      <div className='recommendation1 R2'>
        <div className='recommendation1_Left R2L'>
          <p>Tushar brings fresh ideas and innovation to the team. In his time here, he has challenged our own ideas of what works, pushing forward a design. Tushar is extremely adaptable and responds well to feedback. He is eager to learn new things and step outside of his comfort zone, exhibiting a sense of curiosity that exceeds from of most team members. Lastly, he proactively looks for and shares interesting design patterns he finds in the wild, demonstrating that he's not here just to take orders and do a job, but rather can think strategically about external design elements that may be applicable to our business.</p>
          <div className="title T2">
            <h2>Amanda Peters</h2>
            <p>Digital Product Manager | <span>Rocket Mortgage</span></p>
          </div>
        </div>
        <div className='recommendation1_Right R2R'>
          <img src="./images/Amanda.jpeg" alt="photo" />
        </div>
      </div>

      <div className='recommendation1 R2'>
        <div className='recommendation1_Left R2L'>
          <p>Coming from a tech background, he quickly proved himself as an exceptional experience designer, showcasing remarkable skills and dedication to his craft. Throughout his internship, Tushar drove several successful design initiatives. His ability to blend tech knowledge, design expertise, and fresh ideas led to impactful project solutions. Collaboration is one of Tushar's strengths; he seamlessly works with designers, product managers, and engineers. With passion for design and remarkable work ethic, I have no doubt he'll achieve success. His potential is limitless, and I'm excited to see his future.</p>
          <div className="title T2">
            <h2>Allison O'Keefe</h2>
            <p>Senior Experience Designer | <span>Rocket Mortgage</span></p>
          </div>
        </div>
        <div className='recommendation1_Right R2R'>
          <img src="./images/Allison.png" alt="photo" />
        </div>
      </div>

      </div>
      </div>

    </div>

  )
}

export default Projects
