import React,{useEffect, useRef} from 'react';
import DarkNav from './DarkNav';
import Footer from './Footer';
import "./Fitbit.css";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import Navbar2 from './Navbar2';
import SmoothScroll from "smooth-scroll";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import ScrollToTop from "react-scroll-to-top";




function Fitbit() {

    // useEffect(() => {
    //     window.scrollTo(0,0);
    // },[])


    // const { scrollYProgress } = useScroll();
    // const scaleX = useSpring(scrollYProgress, {
    //   stiffness: 100,
    //   damping: 30,
    //   restDelta: 0.001
    // });

    // useEffect(() => {
    //     window.scrollTo(0,0);
    // },[])

    // var scroll = new SmoothScroll('a[href*="#"]', {
    //     speed: 300,
    // });





    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    var scroll = new SmoothScroll('a[href*="#"]', {
        speed: 300,
    });


  const { scrollYProgress } = useScroll();
  const { scrollY } = useScroll();
  const x = useTransform(scrollY, [3000,5000], [0, -700])


    const scaleX = useSpring(scrollYProgress, {
      stiffness: 100,
      damping: 30,
      restDelta: 0.001
    });

    const ref = useRef(null);
    const { scrollXProgress } = useScroll({ container: ref });


  

  return (
    <>

    <DarkNav />
    <motion.div className="progress-bar" style={{ scaleX }} />
        <div className='fitbit'>
            
            <div className='fitbit_Header'>
                <div className='homeLeft_Fitbit'>
                    <div className='homeLeft_Top_Fitbit'>
                        <p>Designing</p>
                    </div>
                    <div className='homeLeft_Bottom_Fitbit'>
                        <p>For Behavior</p>
                        <p>Change in</p>
                        <p>FitBit</p>
                    </div>
                </div>
                <div className='project_Info'>
                    <div className='project_Info_Timeline'>
                        <h5>TIMELINE</h5>
                        <p>Research // 1 week</p>
                        <p>Visual Design // 3 weeks</p>
                    </div>
                    <div className='project_Info_Role'>
                        <h5>ROLE</h5>
                        <p>UX Designer</p>
                        <p>Team Leader</p>
                    </div>
                    <div className='project_Info_Tools'>
                        <h5>TOOLS</h5>
                        <p>Figma</p>
                    </div>
                    <div className='project_Info_Teams'>
                        <h5>TEAMS</h5>
                        <p>3 grad students & I</p>
                    </div>
                    <div className='project_Info_ProjectType'>
                        <h5>PROJECT TYPE</h5>
                        <p>Academic</p>
                    </div>
                    <div className='project_Info_ProjectFocus'>
                        <h5>PROJECT FOCUS</h5>
                        <p>Research</p>
                        <p>Behavior Change</p>
                        <p>Visual Design</p>
                    </div>
                </div>
            </div>

            <div style={{margin:"25px 0px"}}>
                <p>PROJECT SHOWREEL</p>
            </div>

            <div className='project_Video'>
                <div className='overlay'>
                    
                </div>
            <video autoPlay loop>
                        <source src="/images/fitbitvideo.mp4" type="video/mp4"/>
                    </video>         
            </div>

            <div className='project_Overview'>
                <div className='project_Overview_Left'>
                    <p>OVERVIEW</p>
                    <div className='project_Overview_Left_Link'>
                        <p><a data-scroll style={{textDecoration:"none", color:"#000000"}} href='#solution1'>SKIP TO THE JUICY PART</a></p>
                        <img src='/images/Link_Arrow_Fitbit.svg' alt="link arrow" />
                    </div>
                </div>
                <div className='project_Overview_Right'>
                    <p>Fitbit is an American-based company focusing on fitness by providing its customers with physical, and electronic devices and associated software. Focusing on behavior change, our team considered different features of the Fitbit application; our goal was to become familiar with what the app offered and what we believe can improve. We decided to move on with the Water Intake feature and make the experience better.</p>
                </div>
            </div>

            <div className='project_Vague_ProblemStatement'>
                <h2>A <span>'VAGUE'</span> PROBLEM STATEMENT</h2>
                <p>Initially, before beginning the design journey I went on to define a 'vague' problem statement that would be redefined in the later stages</p>
                <p>How can we encourage and incentivize Fitbit users to drink more water and make it a daily habit that supports their health and fitness goals?</p>
            </div>

            <div className='project_Vague_WhyRedesign'>
                <p>But why is redesign necessary?</p>
                <img src="/images/Fitbit_Redesign.png" alt="old firbit screenshots" />
            </div>

            <div className='project_Border'></div>

            <div className='project_Research_Header'>
                <div className='project_Research_Header_Left'>
                <div className='project_Research_Header_Left_Top'>

                </div>
                <div className='project_Research_Header_Left_Bottom'>
                    <p>Stages:</p>
                </div>

                </div>
                <div className='project_Research_Header_Right'>
                    <div className='project_Research_Header_Right_Top'>
                        <h1>Research</h1>
                        <h1>Process</h1>
                    </div>
                    <div className='project_Research_Header_Right_Bottom'>
                        <p>1. Secondary Research</p>
                        <p>2. Primary Research</p>
                        <p>3. Behavior Change Model</p>
                    </div>
                </div>
            </div>

            <div className='project_Secondary_Research'>
                <div className='project_Secondary_Research_Wrapper'>
                    <div className='project_Secondary_Research_Wrapper_Left'>
                        <p>SECONDARY RESEARCH</p>
                    </div>
                    <div className='project_Secondary_Research_Wrapper_Right'>
                        <p>Through our literature review, we wanted to gain knowledge on general informational facts about water consumption for the average individual. This later influenced our interview protocol due to a deepened understanding of water's effects on the body.</p>
                    </div>
                </div>
                <img src="/images/Fitbit_Secondary_Research.svg" alt='secondary research' />
            </div>

            <div className='project_Border'></div>

            <div className='project_Primary_Research'>
                <div className='project_Primary_Research_Top'>
                    <div className='project_Primary_Research_Top_Left'>
                        <h2>PRIMARY RESEARCH</h2>
                        <p>To validate our findings from our secondary research, we did some interviews with people who uses Fitbit and similar smart watches. We wanted to understand if what kind of issues students were facing.</p>
                    </div>
                    <div className='project_Primary_Research_Top_Right'>
                        <h1>90%</h1>
                        <p>Mentioned <span>dehydration due to forgetting to drink water</span> from being <span>invested in work.</span> </p>
                    </div>
                </div>
                <div className='project_Primary_Research_Bottom'>
                    <div className='project_Primary_Research_Bottom_Left'>
                        <h1>67%</h1>
                        <p><span>Want a tracking feature</span> that didn’t feel like a task to do every-time. </p>
                    </div>
                    <div className='project_Primary_Research_Bottom_Right'>
                        <h1>78%</h1>
                        <p>Understands the benefits of water intake but <span>need incentive to motivate them to drink water.</span> </p>
                    </div>
                </div>
            </div>

            <div className='project_Border'></div>

            <div className='project_BCMIntro'>
                <p>To facilitate behavior change and guide user progression towards healthier habits, we researched frameworks for various domains like health and psychology.</p>
            </div>

            <div className='project_Border'></div>

            <div className='project_TTMModel'>
                <p>TRANSTHEOROTICAL MODEL</p>
                <img src="/images/TTM.svg" alt="TTM model" />
            </div>

            <div className='project_Border'></div>

            <div className='project_Affinity'>
                <div className='project_Affinity_Left'>
                    <h1>SYNTHESIZING INSIGHTS</h1>
                    <p>Based on affinity mapping, we identified behaviors to target. We based each behavior on common themes we found in all interviews.</p>
                    <p>Link to the detailed <a href="#">Affinity Map</a></p>
                </div>
                <div className='project_Affinity_Right'>
                    <img src="/images/Fitbit_AffinityMap.svg" alt="Synthesized Affinity Map" />
                </div>
            </div>

            <div className='project_Vague_ProblemStatement'>
                <h2>THE <span>'FINAL'</span> PROBLEM STATEMENT</h2>
                <p></p>
                <p>How can we Redesign Fitbit’s water intake feature to remind, motivate and educate Fitbit users wanting to lose weight so they can track their water intake daily and make informed decisions about their hydration habits.</p>
            </div>

            <div className='project_Research_Header project_Design_Header'>
                <div className='project_Research_Header_Left'>
                <div className='project_Research_Header_Left_Top'>

                </div>
                <div className='project_Research_Header_Left_Bottom'>
                    <p>Stages:</p>
                </div>

                </div>
                <div className='project_Research_Header_Right'>
                    <div className='project_Research_Header_Right_Top'>
                        <h1>Design</h1>
                        <h1>Process</h1>
                    </div>
                    <div className='project_Research_Header_Right_Bottom'>
                        <p>1. Recreating User Flows</p>
                        <p>2. Initial Version of Wireframes</p>
                        <p>3. User Testing</p>
                        <p>4. Final High Fidelity Prototype</p>
                    </div>
                </div>
            </div>

            <div className='project_Secondary_Research project_IA'>
                <div className='project_Secondary_Research_Wrapper project_IA_Wrapper'>
                    <div className='project_Secondary_Research_Wrapper_Left'>
                        <p>RECREATING THE FLOW</p>
                    </div>
                    <div className='project_Secondary_Research_Wrapper_Right'>
                        <p>We looked at Fitbits current water intake tracking feature and the user's tasks within. We utilized Figjam to create our task flows. Creating flows before designing any aspect of the interface allowed all team members to be on the same page when making initial designs and later iterations.</p>
                    </div>
                </div>
                <img src="/images/Fitbit_IA.svg" alt='secondary research' />
            </div>


            <div className='project_Secondary_Research project_Wireframes '>
            <div className='project_Secondary_Research_Wrapper project_Wireframes_Wrapper'>
                    <div className='project_Secondary_Research_Wrapper_Left'>
                        <p>INITIAL WIREFRAMES</p>
                    </div>
                    <div className='project_Secondary_Research_Wrapper_Right project_Wireframes_Text'>
                        <h2>  &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; Wireframes had been adapted into a visual concept of the app. Interface got a recognizable appearance.</h2>
                        <p>It is important to note that we have developed a number of small but valuable features, such as notifications, fish tank, and onboarding, leaderboard — all to affect the behavior change among users.</p>
                    </div>
                </div>
                <img src="/images/Fitbit_Wireframes.svg" alt="wireframes" />
            </div>

            <div className='project_Testing'>
                <div className='project_Testing_Left'>
                    <h2>TESTING ON FIRST ITERATION</h2>
                    <p>We created a moderated usability test with participants, asking them to complete tasks and noting where they struggled with our designs, informing our final iteration of the UI for the Fitbits water feature.</p>
                    <img src="/images/Fitbit_Testing.png" alt="fitbit testing" />
                </div>
                <div className='project_Testing_Right'>
                    <p>INSIGHTS</p>
                    <div className='project_Testing_Right_Results'>
                        <img src="/images/Caution_Icon.svg" alt="cation icon" />
                        <p>Testing the fish tank screens showed users were frustrated with the slide-up option to change the amount of water they were trying to track.</p>
                    </div>
                    <div className='project_Testing_Right_Results'>
                        <img src="/images/Caution_Icon.svg" alt="cation icon" />
                        <p>The users took a significant time to understand this interaction, causing frustration. </p>
                    </div>
                    <div className='project_Testing_Right_Results'>
                        <img src="/images/Caution_Icon.svg" alt="cation icon" />
                        <p>The user was confused about how the plan would work.</p>
                    </div>
                    <div className='project_Testing_Right_Results'>
                        <img src="/images/Caution_Icon.svg" alt="cation icon" />
                        <p>The brief introduction is not enough, they need more instruction about how their goals will be actually conducted.</p>
                    </div>
                    <div className='project_Testing_Right_Results'>
                        <img src="/images/Caution_Icon.svg" alt="cation icon" />
                        <p>when it comes to the “Rewards and Leaderboard” tabs they get confused about what the water droplet represents.</p>
                    </div>
                </div>
            </div>

            <div className='project_Border'></div>

            <div className='project_Final_Solution' id='solution1' >
                <div className='project_Final_Solution_Top'>
                    <div className='project_Final_Solution_Top_Left'>
                        <p>FINAL SOLUTION</p>
                    </div>
                    <div className='project_Final_Solution_Top_Right'>
                        <p>We created a moderated usability test with participants, asking them to complete tasks and noting where they struggled with our designs, informing our final iteration of the UI for the Fitbits water feature.</p>
                    </div>
                </div>
                <div className='project_Final_Solution_Bottom'>
                    <div className='project_Final_Solution_Bottom_Top'>
                        <div className='project_Final_Solution_Bottom_Top_Left'>
                            <div className='project_Final_Solution_Bottom_Top_Left_Left'>
                                <div className='project_Final_Solution_Bottom_Top_Left_Left_Flex'>
                                    <h2>Habit Formation</h2>
                                    <p>According to users’ chosen goal, the system will recommend supporting habits to help users maximize the effectiveness of drinking water.</p>
                                </div>
                                <img src="/images/Arrow1.svg" alt="arrow" />
                            </div>
                            <div className='project_Final_Solution_Bottom_Top_Left_Right'>
                                <img src="/images/Fitbit1.gif" alt="firbit 1" />
                            </div>
                        </div>
                        <div className='project_Final_Solution_Bottom_Top_Right'>
                            <div className='project_Solution_Goals'>
                                <img src="/images/Fitbit2.svg" alt="fitbit goals" />
                            </div>
                            <div className='project_Solution_Goals_Text'>
                                <div className='project_Solution_Goals_Text_Top'>
                                    <h2>Goal Intensity</h2>
                                    <img src="/images/arrow2.svg" alt="arrow" />
                                </div>
                                <div className='project_Solution_Goals_Text_Bottom'>
                                    <p>Users can choose the intensity of the plan, they could either choose to gradually achieve the ideal quantity of water intake or directly have ideal water intake. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='project_Final_Solution_Bottom_Bottom'>
                        <div className='project_Final_Solution_Tank_left'>
                            <img src="/images/Fitbit3.gif" alt="fish tank" />
                        </div>
                        <div className='project_Final_Solution_Tank_Right'>
                            <div className='project_Final_Solution_Tank_Right_left'>
                                <h2>The Tank</h2>
                                <img src="/images/Arrow 5.svg" alt="arrow" />
                            </div>
                            <div className='project_Final_Solution_Tank_Right_Right'>
                                <p>This page is a gamified fish tank where users care for their fish and interact by tapping the screen. Taking care of the tank encourages users to visit the app regularly and stay hydrated. Logging water increases the tank's water level, simulating a real-life experience.</p>
                            </div>
                        </div>
                    </div>

                    <div className='project_Final_Solution_Bottom_Bottom motivating_messages'>
                        <div className=' motivating_messages_color '>
                            <img src="/images/Fitbit3.gif" alt="fish tank" />
                        </div>
                        <div className='project_Final_Solution_Tank_Right motivating_messages_right'>
                            <div className='project_Final_Solution_Tank_Right_left'>
                                <h2>Motivating Messages</h2>
                            </div>
                            <div className='project_Final_Solution_Tank_Right_Right motivating_messages_right_right'>
                                <p>This page is a gamified fish tank where users care for their fish and interact by tapping the screen. Taking care of the tank encourages users to visit the app regularly and stay hydrated. Logging water increases the tank's water level, simulating a real-life experience.</p>
                                <img src="/images/Arrow 6.svg" alt="arrow" />
                            </div>
                        </div>
                    </div>

                    <div className='project_Final_Solution_Bottom_Bottom Setting_goals '>
                        <div className=' Setting_goals_image'>
                            <img src="/images/Setting Goals.svg" alt="setting goals" />
                        </div>
                        <div className='project_Final_Solution_Tank_Right setting_goals_text'>
                            <div className='project_Final_Solution_Tank_Right_left'>
                                <h2>Setting Goals</h2>
                                <img src="/images/Arrow 5.svg" alt="arrow" />
                            </div>
                            <div className='project_Final_Solution_Tank_Right_Right'>
                                <p>Incase the users choose to skip the onboarding process, they may select goals on the plans page. </p>
                            </div>
                        </div>
                    </div>

                    <div className='project_Final_Solution_Bottom_Bottom track_progress'>
                        <div className=' track_progress_color '>
                            <img className='track_progress_color_image' src="/images/Track progress.svg" alt="fish tank" />
                        </div>
                        <div className='project_Final_Solution_Tank_Right track_progress_right'>
                            <div className='project_Final_Solution_Tank_Right_left'>
                                <h2>Track Progress</h2>
                            </div>
                            <div className='project_Final_Solution_Tank_Right_Right track_progress_right_right'>
                                <p>After selecting the plan, the plan page will turn into the progress chart and to do lists to notify users which step they are at. </p>
                            </div>
                        </div>
                    </div>

                    <div className='project_Final_Solution_Bottom_Bottom Setting_goals insight_stories '>
                        <div className=' Setting_goals_image'>
                            <img src="/images/Insight Stories.svg" alt="Insight Stories" />
                        </div>
                        <div className='project_Final_Solution_Tank_Right insight_stories_text'>
                            <div className='project_Final_Solution_Tank_Right_left'>
                                <h2>Insight Stories</h2>
                                <img style={{marginTop:"40px"}} src="/images/Arrow 5.svg" alt="arrow" />
                            </div>
                            <div className='project_Final_Solution_Tank_Right_Right '>
                                <p>The users can view insights such as ‘benefits of drinking water’, ‘how water helps in losing weight’, etc. This will help them in gaining awareness about the importance of staying hydrated and also adds a fun element to the application. </p>
                            </div>
                        </div>
                    </div>

                    <div className=' gamified_levels'>
                        <div className='project_Final_Solution_Bottom_Top_Left'>
                            <div className='project_Final_Solution_Bottom_Top_Left_Left'>
                                <div className='project_Final_Solution_Bottom_Top_Left_Left_Flex'>
                                    <h2>Gamified Levels</h2>
                                    <p>We are introducing streaks, when users complete their daily goals their level increases and they can unlock new fish tanks.</p>
                                </div>
                                <img src="/images/Arrow1.svg" alt="arrow" />
                            </div>
                            <div className=' gamified_levels_color'>
                                <img src="/images/Gamified levels.svg" alt="Gamified levels" />
                            </div>
                        </div>
                        <div className='project_Final_Solution_Bottom_Top_Right'>
                            <div className='project_Solution_Goals'>
                                <img src="/images/Leaderboard.png" alt="fitbit goals" />
                            </div>
                            <div className='project_Solution_Goals_Text'>
                                <div className='project_Solution_Goals_Text_Top'>
                                    <h2>Leaderboard</h2>
                                    <img src="/images/arrow2.svg" alt="arrow" />
                                </div>
                                <div className='project_Solution_Goals_Text_Bottom'>
                                    <p>The leaderboard is just a fun way to see their friend’s progress. But the main feature here is that the user can notify their friends via a reminder that their friends need to drink water.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='project_Final_Solution_Bottom_Bottom'>
                        <div className=' reminder'>
                            <img src="/images/Reminder.svg" alt="fish tank" />
                        </div>
                        <div className='project_Final_Solution_Tank_Right'>
                            <div className='project_Final_Solution_Tank_Right_left'>
                                <h2>Reminder & Home Screen Widgets</h2>
                                <img src="/images/Arrow 5.svg" alt="arrow" />
                            </div>
                            <div className='project_Final_Solution_Tank_Right_Right'>
                                <p>Customizable reminders solve the annoyance issue. Friends' notifications remind them to drink water. Logging water intake on the home screen via a widget reduces the effort of opening the app to find the feature.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='project_Reflection'>
                <h2>REFLECTION</h2>
                <div className='project_Reflection_Text'>
                    <div className='project_Reflection_Text_Left'>
                        <img src="/images/Reflection1.svg" alt="reflection image" />
                        <h2>Take Aways</h2>
                        <p>The project helped the team learn to design for behavior change in just four weeks, improving their research, interview, and design skills, as well as introducing them to gallery walks and task-based usability testing. <br />  <br /> The team shared their progress with fellow designers during a gallery walk, receiving feedback that will benefit their future projects. We got to implement the design models that we read about. Designing for behavior change provided the team with a new perspective than designing for an experience.</p>
                    </div>
                    <div className='project_Reflection_Text_Right'>
                        <img src="/images/Reflection2.svg" alt="reflection image" />
                        <h2>Future Scope</h2>
                        <p>Moving forward, we would like to test the new iterations of our final prototype with users. It would be helpful to have users test the features more long term than we were able to do within the scope of this project to know if the design was successful in behavior changes within the user population. <br />  <br /> Then we were planning to integrate the Fitbit bottle with the app so it would make it even easier for the user to log water. Apart from this getting the goal’s notification on smartwatch would be helpful for the users.</p>
                    </div>
                </div>
            </div>

            <ScrollToTop 
                color='#FB8842'
                top={500} smooth />
       
        </div>
        <Footer />
        </>
  )
}

export default Fitbit;