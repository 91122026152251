import React from 'react';
import "./Footer.css";

function Footer() {
  return (
    <div className='footer'>
        <div className='footerwrapper'>
            <p>saytotushar@gmail.com</p>
            <div className='footer_Links'>
                <a href='https://www.linkedin.com/in/tushar-sharma-7a24961a4/'>LinkedIn</a> 
                <a href='https://www.instagram.com/sharmatushar_/'>Instagram</a>
            </div>
            <p>Tushar sharma © {(new Date().getFullYear())}</p>
        </div>
        <p style={{marginTop:"30px"}}>Designed and coded by Tushar Sharma</p>
    </div>

  )
}

export default Footer